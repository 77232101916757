<template>
  <div class="bg lbg" style="width: 100%; height: 100%">
    <div class="loginBg">
      <div class="right">
        <div class="title">
          <img src="../../assets/login.png" alt="" />
          <i>四两数字人 </i>
        </div>

        <div v-if="!showExchange">
          <div class="mb30">
            <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="username"></el-input>
          </div>
          <div class="mb20">
            <el-input placeholder="请输入您的密码" prefix-icon="el-icon-lock" @keyup.enter.native="login" show-password v-model="password"></el-input>
          </div>
          <div style="color: #3188e3" @click="showExchange = true">卡密兑换</div>

          <el-button type="primary" class="login" style="" size="medium" @click="login">登录</el-button>
          <div>
            <el-checkbox v-model="checked">
              我已阅读理解并同意 <span style="color: #409eff" @click="openDoc('yh')">《用户协议》</span>和<span style="color: #409eff" @click="openDoc('ys')">《隐私协议》</span>
            </el-checkbox>
          </div>
        </div>

        <div style="width: 100%" v-else>
          <div class="mb30" @click="showExchange = false"><i class="el-icon-back"></i>返回</div>
          <div class="mb30">
            <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="username"></el-input>
          </div>
          <div class="mb20">
            <el-input placeholder="请输入兑换码" prefix-icon="el-icon-lock" @keyup.enter.native="login" v-model="code"></el-input>
          </div>
          <el-button type="primary" class="login" style="" size="medium" @click="exchange">兑换</el-button>
        </div>
      </div>
    </div>
    <div class="contrl">
      <div @click="handleMinus">
        <i class="el-icon-minus"></i>
      </div>
      <div @click="handleClose">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getUser, use_cami } from "@/api/user.js";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";

export default {
  data() {
    return {
      username: "",
      password: "",
      code: "",
      checked: false,
      showExchange: false,
    };
  },
  mounted() {
    this.listenerClose();
  },
  methods: {
    listenerClose() {
      this.$ipc.invoke(ipcApiRoute.closeWin).then((res) => {
        console.log("关闭窗口");
      });
    },
    openDoc(type) {
      console.log(111);
      this.$ipc.invoke(ipcApiRoute.openPath, { fileName: type == "ys" ? "privacy.doc" : "user.doc" }).then((res) => {
        console.log("打开地址");
      });
    },
    exchange() {
      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.code) {
        this.$message.error("卡密不能为空");
        return;
      }

      let params = {
        username: this.username,
        code: this.code,
      };
      use_cami(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.username = "";
          this.showExchange = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.$ipc.invoke(ipcApiRoute.closeApp).then((r) => {});
    },
    handleMinus() {
      this.$ipc.invoke(ipcApiRoute.handleApp, "mini").then((r) => {});
    },
    login() {
      // localStorage.setItem('token', '111')
      // this.$router.push({ name: 'video' })

      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.password) {
        this.$message.error("密码不能为空");
        return;
      }
      if (!this.checked) {
        this.$message.error("请勾选用户协议");
        return;
      }

      let params = {
        username: this.username,
        password: this.password,
      };
      login(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          localStorage.setItem("token", res.data.token);
          getUser({ token: res.data.token }).then((res1) => {
            if (res1.code == "200") {
              localStorage.setItem("userInfo", JSON.stringify(res1.data));
              this.$router.push({ name: "home" });
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.lbg {
  background: url(~@/assets/img/bg.png) no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
}
.loginBg {
  width: 24vw;
  height: 28vw;
  background-color: #fff;
  -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: relative;
  margin-left: 10vw;

  .login {
    width: 94%;
    background: #2773f8;
    margin: 40px 3% 30px;
    height: 45px;
  }

  .right {
    width: 24vw;
    padding: 40px 50px;
    height: inherit;
    position: absolute;
    top: 0;
    text-align: left;
    font-size: 15px;

    .title {
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 10px;
      margin-bottom: 30px;
      img {
        width: 88px;
        height: 75px;
      }
    }
  }
}

::v-deep {
  .el-input__inner {
    background: #f7f7f7 !important;
    border-radius: 6px !important;
    border: 0 !important;
  }
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
.contrl {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 20px;
  justify-content: end;
  position: absolute;
  right: 0;
  width: 100vw;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  -webkit-app-region: drag;

  div {
    width: 30px;
    line-height: 28px;
    -webkit-app-region: no-drag;
  }
}
</style>
